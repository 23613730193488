
export const settingsTable = {
    
    data: () => ({
      settingsTableDanse: false,
      settingsTableWider: false,
      settingsTableBar: false,
      changeHeaders: false,
    }),
    computed: {
      headersList(){
        return this.headers.filter(x => x.view)
      },
    },
    mounted() {
      console.log('mounted mixin')
      //this.getDefaultHeaders()
      this.settingsTableDanse = JSON.parse(localStorage.getItem(`setting-table-${this.$route.name}-dense-table`)) || false
      this.settingsTableWider = JSON.parse(localStorage.getItem(`setting-table-${this.$route.name}-wider-table`)) || false
      
      this.getSaveSortHeaders()
      
    },
    watch: {
      headers:{
        handler (val, oldVal) {
          console.log("watch headers mixin")
          if(oldVal.length > 0){
            this.changeHeaders = true
            //this.saveHeaders()
          }
        },
        deep: true,
      },
      settingsTableDanse(){
        localStorage.setItem(`setting-table-${this.$route.name}-dense-table`, JSON.stringify(this.settingsTableDanse))
      },
      settingsTableWider(){
        localStorage.setItem(`setting-table-${this.$route.name}-wider-table`, JSON.stringify(this.settingsTableWider)) 
        this.getSaveSortHeaders()
      }
    },
    methods: {
      getDefaultHeaders(){
        this.parseHeaders(this.headersSortDefault[this.settingsTableWider? 'more' : 'default'], true)
      },
      saveHeaders(){
        /** Зберігаємо заголовки таблиці в сховище */
        let headersListSortAndView = []
        this.headers.forEach(header => {
          headersListSortAndView.push({value:header.value, view: header.view})
        })
        localStorage.setItem(`setting-table-${this.$route.name}-headers-${this.settingsTableWider? 'more' : 'default'}`, JSON.stringify(headersListSortAndView))  
        setTimeout(() => {this.changeHeaders = false},10)
      },
      getSaveSortHeaders(){
        /** Дістаємо заголовки таблиці зі сховища */
        let headersListSortAndView = JSON.parse(localStorage.getItem(`setting-table-${this.$route.name}-headers-${this.settingsTableWider? 'more' : 'default'}`))
        
        if(!headersListSortAndView){
          this.getDefaultHeaders()
          return
        }else this.parseHeaders(headersListSortAndView)
      },
      parseHeaders(headersListSortAndView, is_default=false){
        
        let headersDefault = JSON.parse(JSON.stringify(this.headersDefault))

        

        /** Сортуємо список заголовків таблиці відносно отриманого порядку */
      
        const updatedAndSortedList = headersDefault
        .map(item => {
          // Знайти відповідний об'єкт у referenceList
          const referenceItem = headersListSortAndView.find(ref => ref.value === item.value);
          
          // Повернути новий об'єкт, який об'єднує властивості з listToSort і referenceList
          if(referenceItem){
            item['view'] = referenceItem.view
          }
          return item 
        })
        .sort((a, b) => {
          // Сортувати відносно індексів у referenceList
          const indexA = headersListSortAndView.findIndex(ref => ref.value === a.value);
          const indexB = headersListSortAndView.findIndex(ref => ref.value === b.value);
          return indexA - indexB;
        })
        if(is_default && this.$route.name != 'pays' && this.user?.operator_id != 1){ // якщо оператор "Altair"
          updatedAndSortedList.find(x => x.value == "operator_group").view = false
        }
        this.headers = updatedAndSortedList
        !is_default && setTimeout(() => {this.changeHeaders = false},10)
      },
    },
      

}